<template>
  <div>

    <!-- Whitelabel Info: Input Fields -->
    <b-form>
      <b-row class="justify-content-center">
        <!-- Field: Whitelabel Name -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Client"
            label-for="menu"
          >
            <b-form-select
              input-id="menu"
              v-model="whitelabelData.page.menu"
              :options="menus"
              value-field="_id"
              text-field="title"
              @change="changeMenu()"
              />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
    <div>
      <div class="border border-primary *-border-primary rounded p-1 mb-1" v-for="(item, index) in menuData.links" :key="index"> 
        <b-row>
          <b-col>
            <!-- title -->
              <b-form-group
                label="Title"
                label-for="title"
              >
                <b-form-input
                  id="title"
                  v-model="item.title"
                  trim
                />
              </b-form-group>
          </b-col>
          <b-col>
            <!-- Icon -->
              <b-form-group
                label="Icon"
                label-for="icon"
              >
                <b-form-input
                  id="icon"
                  v-model="item.icon"
                  trim
                />
              </b-form-group>
          </b-col>
          <b-col>
          <!-- Color -->
          <b-form-group
            label="Color"
            label-for="color"
          >
            <b-form-input
              id="color"
              v-model="item.color"
              trim
            />
          </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <!-- Link -->
            <b-form-group
              label="Type"
              label-for="type"
            >
              <b-form-select
                input-id="type"
                v-model="item.type"
                :options="types"
                @change="changetype(item)"
              />
              </b-form-group>
          </b-col>
          <b-col>
            <!-- Link -->
            <b-form-group
              label="Page"
              label-for="Page"
            >
              <b-form-select
                input-id="Page"
                v-model="item.page"
                :options="statics"
                v-if="item.type == 'static'"
                @change="LinkEdit(item)"
              />
              <b-form-select
                input-id="Page"
                v-model="item.page"
                :options="whitelabelData.products"
                value-field="productId"
                text-field="name"
                @change="LinkEdit(item)"
                v-else
              />
              </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="Link"
              label-for="link"
            >
              <b-form-input
                id="link"
                v-model="item.href"
                trim
                disabled
              />
            </b-form-group>
          </b-col>
          
        </b-row>
          <div class="text-center w-100">

            <b-button
              variant="outline-danger"
              @click="removeItem(index)"
              >
              <feather-icon
                  icon="XIcon"
                  class="mr-25"
              />
              <span>Delete</span>
          </b-button>
          </div>
      </div>
    </div>
    <div class="text-center w-100 mt-1">
      <b-button
        variant="outline-primary"
        @click="pushItem()"
        >
        <feather-icon
            icon="PlusIcon"
        />
        <span>Add Link</span>
      </b-button>
      <!-- <b-button
        class="ml-1"
        variant="outline-info"
        @click="pushItem()"
        >
        <feather-icon
            icon="PlusIcon"
        />
        <span>Add Submenu</span>
      </b-button> -->
    </div>

    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="updateWhitelabel(whitelabelData._id)"
    >
      Save Changes
    </b-button>
    
  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox, BFormSelect,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref, onUnmounted} from '@vue/composition-api'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import menuStoreModule from '@/views/pages/menu/menuStoreModule'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormSelect,
    vSelect,
  },
  props: {
    whitelabelData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const CLIENT_APP_STORE_CLIENT_NAME = 'app-menu'
    if (!store.hasModule(CLIENT_APP_STORE_CLIENT_NAME)) store.registerModule(CLIENT_APP_STORE_CLIENT_NAME, menuStoreModule)
    onUnmounted(() => {
      if (store.hasModule(CLIENT_APP_STORE_CLIENT_NAME)) store.unregisterModule(CLIENT_APP_STORE_CLIENT_NAME)
    })

    const menus = ref([])
    store.dispatch('app-menu/fetchMenus')
      .then(response => { 
        menus.value = response.data.items
      })

    return {
      menus
    }
    
  },
  data() {
    return {
      types: [
          { value: 'product', text: 'Producto' },
          { value: 'static', text: 'Pagina Estatica' }
        ],
      statics: [
        { value: 'contact', text: 'Contactos' },
        { value: 'faq', text: 'FAQ' }
      ],
      menuData:[]
    }
  },
  beforeMount()
  {
    if(!this.whitelabelData.page ) 
    {
      this.whitelabelData.page = [];
      this.whitelabelData.page.menu = '';
    }
  },
  methods: {
    changetype(item)
    {
      item.href = '';
      item.page = '';
    },
    changeMenu()
    {
      const filterMenu = this.menus.filter(menu => menu._id === this.whitelabelData.page.menu);
      this.menuData =  filterMenu[0];
    },
    LinkEdit(item){

      console.log(item);
      if(item.type == 'static')
      {
        item.href = `/${item.page}`;
      }
      else
      {
        const filterProduct = this.whitelabelData.products.filter(product => product.productId === item.page);
        if(filterProduct[0].isgame) item.href = `/product/${item.page}`
        else item.href = `/lobby/${item.page}`
      }
      
    },
    updateWhitelabel(Id) {
      console.log(this.whitelabelData)
      store.dispatch('app-whitelabel/updateWhitelabelPage', { id: Id, data: this.whitelabelData.page})
        .then(() => {
            store.dispatch('app-menu/updateMenu', { id: this.menuData._id, menuData: this.menuData})
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Whitelabel updated',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
            })
        })
    },

    removeItem(index) {
    
      // console.log(index)
      this.menuData.links.splice(index, 1)
      //.trTrimHeight(this.$refs.row.offsetHeight)
    },
    pushItem()
    {
      this.menuData.links.push({})
    }

  },
  watch: {
    menus: function () { 
      const filterMenu = this.menus.filter(menu => menu._id === this.whitelabelData.page.menu);
      if(this.whitelabelData.page.menu){

        this.menuData =  filterMenu[0];
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
